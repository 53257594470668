import * as React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// icons
import PhoneIcon from "@mui/icons-material/Phone";

// components
import Page from "../../utils/page";
import Link from "../../utils/link";
import Hero from "../../utils/hero";
import Section from "../../utils/section";
import AreeServiziCard from "../../components/aree-e-servizi";
import theme from "../../utils/theme";

// markup
const TamponeCovid = () => {
    return (
        <Page
            title="Tampone antigenico COVID-19"
            description="È possibile eseguire su prenotazione tampone rapido COVID-19 direttamente nella propria auto. Chiamaci e pronta il tuo tampone rapido."
        >
            <Hero
                color="#fff"
                title="Tampone COVID-19"
                description="È possibile eseguire su prenotazione tampone rapido COVID-19 direttamente nella propria auto."
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../../images/farmacia-settimo-miglio-cosmesi.jpg"
                        alt="Farmacia Settimo Miglio tampone Covid-19"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom component="h3" color="primary">
                            Come funziona
                        </Typography>
                        <Typography>
                            Chiama il numero{" "}
                            <Link type="external" href="tel:+390457150797" title="Prenota tampone covid via telefono">
                                0457150797
                            </Link>{" "}
                            per prenotare il tuo appuntamento.
                        </Typography>
                        <Typography>
                            Si ricorda che per l'esecuzione del tampone, non devi avere sintomi riconducibili alla
                            Sars-Cov2 (come febbre superiore a 37,5°) e non devi essere sottoposto a quarantena.
                        </Typography>

                        <Box pt={3}>
                            <Link type="external" href="tel:+390457150797" title="Prenota tampone covid via telefono">
                                <Button size="large" variant="contained" color="info" endIcon={<PhoneIcon />}>
                                    Chiamaci
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Section>

            <AreeServiziCard />
        </Page>
    );
};

export default TamponeCovid;
